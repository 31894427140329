import React from 'react';
import {observer} from "mobx-react";
import {BimFile} from "../Utilities/Classes/BimFile";
import {useViewer} from "../Utilities/Hooks/useViewer";
import {Contents} from "../Utilities/Classes/Contents";

export const ForgeViewable = observer(({contents, file}: {contents: Contents, file: BimFile})=>{

    useViewer(file, "lmvDiv");

    return <div className={'viewerContainer'} style={{position: "relative", display: 'flex', height: '100%', flexDirection: 'column', flex: 1}}>
        <div id={"lmvDiv"} style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'}}
        />
    </div>
})