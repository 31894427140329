import React, {useState} from 'react';
import {observer} from "mobx-react";
import {TreeItem, TreeView} from "@mui/lab";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderIcon from "@mui/icons-material/Folder";
import {BatchContents, BatchData} from "../Utilities/Classes/BatchContents";
import {FileIcon} from "./FileIcon";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {Button} from "@mui/material";

export const BatchTree = observer(({ contents, ecosysParams }: { contents: BatchContents, ecosysParams: EcoSysParams })=>{
    const history = useHistory();
    const { search } = useLocation();

    function clickTreeItem(node: BatchData){
        if(node.fileData) {
            history.push(`/batch/singleFile/${node.fileData.itemId}/${search}`)
        }
    }

    const renderTree = (node: BatchData) => (
        <TreeItem
            icon={node.fileData ? <FileIcon file={node.fileData}/> : undefined}
            key={node.id}
            nodeId={node.id}
            label={node.name}
            onClick={()=>clickTreeItem(node)}
        >
            {Array.isArray(node.children)
                ? node.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    return (
        <>
            <BatchTreeHeader ecosysParams={ecosysParams}/>
            <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<FolderOpenIcon />}
                defaultExpanded={buildDefaultExpanded(contents.root)}
                defaultExpandIcon={<FolderIcon />}
            >
                {contents.root?.children?.map((node) => renderTree(node))}
            </TreeView>
        </>
    )
})

const BatchTreeHeader = ({ecosysParams}: {ecosysParams: EcoSysParams}) =>{
    const ancestors = ecosysParams.PathNameAncestors;
    const [show, setShow] = useState(false)

    return(
        <div style={{display: 'flex', alignItems: 'baseline'}}>
            <h3>{ancestors[1]}</h3>
            {show ? <div style={{marginLeft: '20px'}}>{[ancestors[0]].concat(ancestors.slice(2)).join(' / ')}</div> : <></>}
            <Button size={'small'} onClick={()=>setShow(!show)}>{show ? <>&lt;</> : <>&gt;</>}</Button>
            <div style={{flex: 1}}/>
        </div>
    )
};

function buildDefaultExpanded(nodes: BatchData) {

    if (!nodes)
        return [];

    var ids = [nodes.id]

    if (nodes.children) {
        for (let i = 0; i < nodes.children.length; i++) {
            var childIds = buildDefaultExpanded(nodes.children[i])
            if (childIds)
                childIds.map(id => ids.push(id));
        }
    }
    return ids;
}