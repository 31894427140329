import React, {useMemo} from 'react';
import {observer} from "mobx-react";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Button, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {ForgeViewable} from "../Components/ForgeViewable";
import {NonViewable} from "../Components/NonViewable";
import {BimFile} from "../Utilities/Classes/BimFile";
import {Header} from "../Components/Header";
import {OfficeViewable} from "../Components/OfficeViewable";
import {GoogleViewable} from "../Components/GoogleViewable";
import {BatchContents} from "../Utilities/Classes/BatchContents";

export const MassUpdateSingleFilePage = observer(({contents, ecosysParams}: {contents: BatchContents, ecosysParams: EcoSysParams})=>{

    const {itemId, source} = useParams<{itemId: string, source: string}>();

    const file = useMemo(()=>{
        return contents.files.find(x=>x.itemId === itemId);
    }, [contents, itemId])

    function getViewable(){
        if(source && file){
            const sourceFile = new BimFile({
                name: "",
                itemId: file.sourceId,
                objectId: '',
                versionId: file.sourceVersionId,
                derivativeId: '',
                fileType: file.fileType,
                version: 0,
                lastUpdate: "",
                url: '',
                s3Url: ''

            }, contents)


            if(file?.isForgeViewable) return <ForgeViewable file={sourceFile} contents={contents}/>
            else if (file?.isMicrosoftViewable) return <OfficeViewable file={sourceFile}/>
            else if (file?.isGoogleViewable) return <GoogleViewable file={sourceFile}/>
            else if (file) return <NonViewable file={sourceFile} contents={contents}/>
            else return <Typography>No files to display</Typography>
        } else {

            if(file?.isForgeViewable) return <ForgeViewable file={file} contents={contents}/>
            else if (file?.isMicrosoftViewable) return <OfficeViewable file={file}/>
            else if (file?.isGoogleViewable) return <GoogleViewable file={file}/>
            else if (file) return <NonViewable file={file} contents={contents}/>
            else return <Typography>No files to display</Typography>
        }
    }


    return (
        <>
            <Header
                leftButtons={<></>}
                contents={contents}
            >
                <Button
                    variant={'outlined'}
                    onClick={()=>window.open(window.location.href, '_blank')}
                >Open in New Window</Button>

            </Header>

            <main>
                {getViewable()}
            </main>
        </>
    )
})