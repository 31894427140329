import React from 'react';
import {Header} from "../Components/Header";

export const ErrorPage = ({errorMessage}: { errorMessage?: string })=>{

    return (
        <>
            <Header/>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', padding: '15px', alignItems: "center", height: '100%', justifyContent: 'center'}}>
                <h1>Error</h1>
                <div style={{whiteSpace: 'pre-line', textAlign: 'center'}}>{errorMessage}</div>
            </div>
        </>
    )
}