import React, {useEffect, useState} from 'react';
import {ApiCalls} from "./Utilities/ApiCalls";
import {LoginPage} from "./Pages/LoginPage";
import {LoadingPage} from "./Pages/LoadingPage";
import {Route, Switch} from 'react-router-dom';
import {useEcoSysParams} from "./Utilities/Hooks/useEcoSysParams";
import {BatchContents} from "./Utilities/Classes/BatchContents";
import {MissingParametersPage} from "./Pages/MissingParametersPage";
import {NotAuthorizedPage} from "./Pages/NotAuthorizedPage";
import {appState} from "./Utilities/AppState";
import {NotCreated} from "./Pages/NotCreatedPage";
import {NotSetup} from "./Pages/NotSetupPage";
import {ErrorPage} from "./Pages/ErrorPage";
import {NotInEcoSys} from "./Pages/NotInEcoSysPage";
import {EcoSysCredentialsProblemPage} from "./Pages/EcoSysCredentialsProblemPage";
import {MassUpdatePage} from "./Pages/MassUpdatePage";
import {MassUpdateSingleFilePage} from "./Pages/MassUpdateSingleFilePage";
import {SettingsPage} from "./Pages/SettingsPage";

export function MassUpdateApp() {
    const [contents, setContents] = useState<BatchContents>();
    const [loginUrl, setLoginUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [hubName, setHubName] = useState<string>("Hub Not Found");
    const [projectDoesNotExist, setProjectDoesNotExist] = useState<boolean>(false);
    const [projectNotSetup, setProjectNotSetup] = useState<boolean>(false);
    const [forbidden, setForbidden] = useState<any | boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const ecoSysParams = useEcoSysParams();

    useEffect(()=>{
        if(ecoSysParams?.isValidForMassUpdate){
            setLoading(true);
            appState.setLoading(true)
            ApiCalls.GetTree({ecoSysParams: ecoSysParams, isMassUpdate: true})
                .then((response)=>{
                    const contents = new BatchContents(response, ecoSysParams);
                    setContents(contents);
                    appState.setContents(contents);
                    appState.setEcoSysParams(ecoSysParams);
                })
                .catch((err)=>{
                    switch (err.response?.status){
                        case 401:
                            //If unauthorized, user must log in
                            return ApiCalls.GetLoginUrl()
                                .then(result=>{
                                    setLoginUrl(result);
                                })
                                .catch(()=>{
                                    setError(true);
                                })
                        case 404:
                            //If the project doesn't exist, then notify the user
                            if(err.response.data?.errorCode === "NoProject"){
                                setProjectDoesNotExist(true);
                            } else if (err.response.data?.errorCode === "NoEcoSysFolder"){
                                setProjectNotSetup(true);
                            } else {
                                setProjectDoesNotExist(true);
                            }
                            setHubName(err.response.data?.accountName);
                            break;
                        case 403:
                            //If the project exists, but the user isn't allowed to see it
                            console.log("FORBIDDEN", err.response.data);
                            setForbidden(err.response.data || true);
                            break;
                        case 400:
                            setError(true);
                            setErrorMsg(err.response.data);
                    }
                })
                .finally(()=>{
                    setLoading(false);
                    appState.setLoading(false)
                })
        } else {
            setLoading(false);
            appState.setLoading(false)
        }
    }, [ecoSysParams])

    useEffect(()=>{
        window.onfocus =()=>{
            if(!appState.contents && !appState.loading){
                window.location.reload();
            }
        }
    }, [])

    if(loading){
        return <LoadingPage/>
    } else if(!ecoSysParams?.isValidForMassUpdate){
        return <MissingParametersPage/>
    } else if(loginUrl) {
        return <LoginPage loginUrl={loginUrl}/>
    } else if(forbidden.result === 'notallowed'){
        return <NotAuthorizedPage/>
    } else if(forbidden.result === 'dne'){
        return <NotInEcoSys />
    } else if(forbidden.result === 'ecosysauthfailed'){
        return <EcoSysCredentialsProblemPage />
    } else if(projectDoesNotExist){
        return <NotCreated hubName={hubName}/>
    } else if(projectNotSetup){
        return <NotSetup hubName={hubName}/>
    }  else if(error){
        return <ErrorPage errorMessage={errorMsg}/>
    } else {
        if(contents){
            return (
                <Switch>
                    <Route path={"/massUpdate/settings"} render={()=><SettingsPage contents={contents}/>}/>
                    <Route exact path={'/massUpdate/singleFile/:itemId/:source?'} render={()=><MassUpdateSingleFilePage ecosysParams={ecoSysParams} contents={contents!}/>}/>
                    <Route exact path={'/massUpdate'} render={()=><MassUpdatePage ecosysParams={ecoSysParams} contents={contents!}/>}/>
                </Switch>
            )
        }
        else {
            return <NotAuthorizedPage/>
        }
    }
}
