import React from 'react';
import {useEcoSysParams} from "../Utilities/Hooks/useEcoSysParams";
import {Header} from "../Components/Header";

export const NotAuthorizedPage = ()=>{
    const ecoSysParams = useEcoSysParams();
    const name = [ecoSysParams?.ProjectId, ecoSysParams?.ProjectName]
        .filter(x=>x)
        .map((x)=>decodeURIComponent(x!))
        .join(" - ")

    return (
        <>
            <Header/>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', padding: '15px', alignItems: "center", height: '100%', justifyContent: 'center'}}>
                <h1>You do not have access to project <u>{name}</u></h1>
                <h2>Please contact your EcoSys Admin to be granted access.</h2>
            </div>
        </>
    )
}