import {IBatchBimFile, BatchFile} from "./BatchFile";
import {makeAutoObservable} from "mobx";
import { EcoSysParams } from "./EcoSysParams";

export interface IBatchContentsResponse{
    hubId: string;
    projectId: string;
    folderId: string;
    authorized: boolean;
    isApplicationAdmin: boolean;
    files: IBatchBimFile[];
}

export class BatchData {
    constructor(id: string, name: string, fileData?: BatchFile) {
        this.id = id;
        this.name = name;
        this.fileData = fileData;
    }

    public id: string;
    public name: string;
    public fileData?: BatchFile;
    public children: BatchData[] | undefined;

    public get childIds(): string[]{
        return (this.children ?? [])
            .reduce((acc: string[], val)=>{
                return acc.concat(val.childIds);
            }, [])
            .concat(this.fileData ? [this.id] : [])
    }

    public get childFiles(): BatchFile[]{
        return (this.children ?? [])
            .reduce((acc: BatchFile[], val)=>{
                return acc.concat(val.childFiles);
            }, [])
            .concat(this.fileData ? [this.fileData] : [])
    }

    public addChild(child: BatchData){
        if(!this.children) this.children = [];
        this.children.push(child);
    }
}

export class BatchContents{
    public readonly hubId: string;
    public readonly projectId: string;
    public readonly folderId: string;
    public readonly authorized: boolean;
    public readonly isApplicationAdmin: boolean;
    public files: BatchFile[];
    public root: BatchData;
    public ecoSysParams: EcoSysParams;

    constructor(params: IBatchContentsResponse, ecoSysParams: EcoSysParams) {
        makeAutoObservable(this);

        this.hubId = params.hubId;
        this.projectId = params.projectId;
        this.folderId = params.folderId;
        this.authorized = params.authorized;
        this.isApplicationAdmin = params.isApplicationAdmin;
        this.files = params.files.map(file => {
            const batchFile = new BatchFile(file, this)
            batchFile.simplifyPathNameAncestors(ecoSysParams);
            return batchFile;
        });
        this.root = this.buildRoot(ecoSysParams.PathNames);
        this.ecoSysParams = ecoSysParams;
    }

    private buildRoot(rootName: string) {
        const rootNode = new BatchData(`root`, decodeURIComponent(rootName));

        for(let i = 0; i < this.files.length; i++){
            const file = this.files[i];
            let lastFolder: BatchData = rootNode;
            file.simplifiedPathNameAncestors.forEach((ancestor, index)=>{
                const folder = lastFolder.children?.find(x=>x.name === ancestor);

                if(folder){
                    lastFolder = folder;
                } else {
                    const newFolder = new BatchData(`folder-${i}-${index}`, ancestor);
                    lastFolder.addChild(newFolder);
                    lastFolder = newFolder;
                }
            })
            const batchId = `file-${i}`;
            file.setBatchId(batchId);
            lastFolder.addChild(new BatchData(batchId, file.name, file))
        }
        return rootNode;
    }

    public removeFile(file: BatchFile){
        this.files = this.files.filter(f=>f !== file);
    }

    public get folderUrl(){
        return `https://docs.b360.autodesk.com/projects/${this.projectId}/folders/${this.folderId}/detail`
    }

}