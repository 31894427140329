import React, {useEffect, useState} from 'react';
import {BatchContents} from "../Utilities/Classes/BatchContents";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Box, Button, Checkbox, Tab, Tabs, Typography} from "@mui/material";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import {FileIcon} from "../Components/FileIcon";
import {BatchFile} from "../Utilities/Classes/BatchFile";
import {useHistory} from 'react-router-dom';
import {useLocation} from "react-router";
import {observer} from "mobx-react";
import {Header} from "../Components/Header";
import {MassUpdateTree} from "../Components/MassUpdateTree";
import {ApiCalls} from "../Utilities/ApiCalls";
import {UpdatingFilesAlert} from "../Components/UpdatingFilesAlert";
import {MassUpdateFileMenu, MassUpdateFileMenuButton} from "../Components/MassUpdateFileMenu";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                //@ts-ignore
                <Box sx={{ p: 3 }} {...other}>
                    <Typography {...other}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const MassUpdatePage = observer(({ contents, ecosysParams }: { contents: BatchContents, ecosysParams: EcoSysParams }) => {

    const history = useHistory();
    const { search } = useLocation();
    const [selected, setSelected] = useState<string[]>([])
    const [updatingFiles, setUpdatingFiles] = useState<BatchFile[]>()
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<Element>();
    const [file, setFile] = React.useState<BatchFile>();
    const [filter, setFilter] = React.useState(true);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(()=>{
        selectAll();
    }, [contents.files])

    function selectAll(){
        const allPossible: string[] = contents.files?.filter(x=>x.behindSource).map(x=>x.batchId).filter(x=>x) as string[] ?? [];
        setSelected(allPossible.length === selected.length ? [] : allPossible);
    }

    function setHiddenColumns(colDefs: any){
        const object = Object.keys(colDefs).reduce((acc: any, val)=>{
            const def = colDefs[val];
            acc[def.field] = def.hide;
            return acc;
        }, {})

        window.localStorage.setItem('columnState', JSON.stringify(object));
    }

    function massUpdate(files?: BatchFile[]){
        const filesToUpdate = files ?? contents.files.filter(x=>x.behindSource && selected.indexOf(x.batchId!) > -1);
        setUpdatingFiles(filesToUpdate);

        ApiCalls.UpdateFiles({ecoSysParams: ecosysParams, files: filesToUpdate})
            .then(()=>{
                window.location.reload();
            })
            .catch((e)=>{
                window.alert(`Could not update all files: ${e.message}`)
                setUpdatingFiles(undefined);
            })
    }

    const columnState = JSON.parse(window.localStorage.getItem('columnState') ?? "{}");

    const columns: GridColDef[] = [
        { field: "checked", width: 50, renderCell: (params => <Checkbox
                disabled={!params.row.behindSource}
                checked={params.row.behindSource && selected.indexOf(params.row.batchId) > -1}
            />) },
        { field: "fileType", hide: columnState["fileType"] ?? false, width: 50, renderCell: (params => <FileIcon file={params.row as BatchFile} />) },
        {
            field: "name", headerName: "File Name", flex: 1,
            renderCell: (params: GridCellParams) => {
                return (<Button style={{color: params.row.behindSource ? undefined : '#aaa', justifyContent: "start" }} size={"small"}>{params.value}</Button>)
            }
        },
        { field: "behindSource", hide: columnState["behindSource"] ?? false, headerName: "Status", width: 200,
            valueGetter: (params)=>params.row[params.field],
            renderCell: x=>(<div>
                {x.row.behindSource === undefined ? <span style={{color: 'purple'}}>Unlinked from Source File</span> : <></>}
                {x.row.behindSource === true ? <span style={{color: 'red'}}>Out of Date</span> : <></>}
                {x.row.behindSource === false ? <span>Up to Date</span> : <></>}
            </div>)},
        {field: "0", hide: columnState["0"] ?? false, width: 150, headerName: "Phase",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "1", hide: columnState["1"] ?? false, width: 150, headerName: "Basis",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "2", hide: columnState["2"] ?? false, width: 150, headerName: "Category",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "3", hide: columnState["3"] ?? false, width: 150, headerName: "Description",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "4", hide: columnState["4"] ?? false, width: 150, headerName: "Header",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "menu", width: 100, headerName: "Menu",
            renderCell: x=>( <MassUpdateFileMenuButton file={x.row as BatchFile} setAnchorEl={setAnchorEl} setMenuFile={setFile}/>)},
    ]


    if (contents.files.length === 1) {
        history.push(`/batch/singleFile/${contents.files[0].itemId}/${search}`)
    }

    return (
        <>
            {updatingFiles && updatingFiles.length ? <UpdatingFilesAlert files={updatingFiles}/> : <></>}
            <MassUpdateFileMenu
                file={file}
                anchorEl={anchorEl}
                massUpdate={massUpdate}
                handleClose={()=> {
                setAnchorEl(undefined);
                setFile(undefined)

            }}/>
            <Header contents={contents}>

            </Header>

            <main style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>


                <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <Button style={{width: '175px'}} variant={'contained'} onClick={()=>selectAll()}>Select All</Button>
                    <Button
                        style={{width: '325px'}}
                        disabled={!selected.length}
                        variant={'contained'}
                        color={'primary'}
                        onClick={()=>massUpdate()}
                    >
                        Update selected files
                    </Button>
                    <div style={{width: '15em'}}>
                        <Checkbox checked={!filter} onChange={(e)=>setFilter(!e.currentTarget.checked)}/> Show All Files
                    </div>

                    <div style={{flex: 'auto'}}>
                        Instructions: Please choose out-of-date files using the checkbox, then press the 'Update' button to pull the latest version of the source file into the Phase.
                    </div>
                </div>

                {/*@ts-ignore*/}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {/*@ts-ignore*/}
                    <Tabs value={value} onChange={handleChange} aria-label="Tree and List View Tabs">
                        <Tab label="Tree View" {...a11yProps(0)} />
                        <Tab label="List View" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                {/*@ts-ignore*/}
                <TabPanel value={value} index={0} style={{ flex: 1, height: '100%' , overflowY: 'auto'}}>
                    <div style={{overflowY: 'auto'}}>
                    <MassUpdateTree
                        contents={contents}
                        ecosysParams={ecosysParams}
                        selected={selected}
                        setSelected={setSelected}
                        setAnchorEl={setAnchorEl}
                        setFile={setFile}
                        filter={filter}
                    />
                    </div>
                </TabPanel>
                {/*@ts-ignore*/}
                <TabPanel style={{ flex: 1, height: '100%' }} value={value} index={1}>
                    <DataGrid
                        density={"compact"}
                        rows={contents.files.filter(x=>x.behindSource || !filter)}
                        columns={columns}
                        // autoPageSize={true}
                        disableSelectionOnClick
                        onStateChange={(e)=>setHiddenColumns(e.state.columns.lookup)}
                        onCellClick={(cell) => {
                            if (cell.colDef.headerName !== 'Actions') {
                                const bimFile = cell.row as BatchFile;
                                if(selected.indexOf(bimFile.batchId!) > -1){
                                    setSelected(selected.filter(x=>x !== bimFile.batchId))
                                } else if(bimFile.behindSource) {
                                    setSelected(selected.concat([bimFile.batchId!]))
                                }
                            }
                        }}
                    />
                </TabPanel>
            </main>
        </>
    )
})