import {ApiCalls} from "../ApiCalls";
import {makeAutoObservable, runInAction} from "mobx";
import {appState} from "../AppState";
import {BimFile} from "./BimFile";

export interface IFolder{
    folderId: string;
    name: string;
}

export class Folder{
    public folderId: string;
    public name: string;
    public childFolders: Folder[] = [];
    public childFiles: BimFile[] = [];
    public loaded: boolean = false;

    constructor(props: IFolder) {
        makeAutoObservable(this);
        this.folderId = props.folderId;
        this.name = props.name;

        if(this.childFolders.length === 0 && this.childFiles.length === 0){
            this.childFiles = [new BimFile({
                fileType: 'empty',
                name: '-- No Contents --',
                objectId: '',
                itemId: '',
                versionId: '',
                derivativeId: '',
                lastUpdate: '',
                version: 0,
                url: '',
                s3Url: ''
            }, appState.contents!)]
        }

        this.load = this.load.bind(this);
    }

    public load(){
        if(!this.loaded){
            this.childFiles = [new BimFile({
                fileType: 'empty',
                name: 'Loading...',
                objectId: '',
                itemId: '',
                versionId: '',
                derivativeId: '',
                lastUpdate: '',
                version: 0,
                url: '',
                s3Url: ''
            }, appState.contents!)]

            ApiCalls.GetFolderContents({projectId: appState.ecoSysParams!.ProjectId, folderId: this.folderId, ecoSysParams: appState.ecoSysParams!})
                .then(({folders, files})=>{
                    runInAction(()=>{
                        this.childFolders = folders.map(val=>new Folder(val));
                        this.childFiles = files.map(val=>new BimFile(val, appState.contents!, this.folderId));
                        this.loaded = true;
                        appState.addFolders(this.childFolders);
                        appState.addFiles(this.childFiles);

                        if(folders.length + files.length === 0){
                            this.childFiles = [new BimFile({
                                fileType: 'empty',
                                name: '-- No Contents --',
                                objectId: '',
                                itemId: '',
                                versionId: '',
                                derivativeId: '',
                                lastUpdate: '',
                                version: 0,
                                url: '',
                                s3Url: ''
                            }, appState.contents!)];
                        }
                    })
                })
        }
    }

    public get childFolderIds(): string[]{
        return this.childFolders.reduce((acc: string[], val)=>acc.concat([val.folderId].concat(val.childFolderIds)), []);
    }

    public get childFolderHash(): {[key: string]: Folder}{
        return this.childFolders
            .reduce((acc: {[key: string]: Folder}, val)=>{
                return {[val.folderId]: val, ...acc, ...val.childFolderHash}
            }, {});
    }

    public get childFileHash(): {[key: string]: BimFile}{
        return this.childFolders
            .reduce((acc: {[key: string]: BimFile}, val: Folder)=>{

                const files = this.childFiles.reduce((hash: {[key: string]: BimFile}, file)=>{
                    hash[file.itemId] = file;
                    return hash;
                }, {})

                return {...files, ...acc, ...val.childFileHash}
            }, {});
    }

    public get id(){return this.folderId}
    public get children(){
        return (this.childFolders
                .slice()
                .sort((a,b)=>a.name.localeCompare(b.name, 'en')) as (BimFile | Folder)[]
        ).concat(this.childFiles
            .slice()
            .sort((a,b)=>a.name.localeCompare(b.name, 'en')));
    }
}

export interface IFolderPermission{
    accountAdmins: IFolderPermissionUser[]
    projectAdmins: IFolderPermissionUser[]
    specificUsers: IFolderPermissionUser[]
    companies: IFolderCompany[];
    roles: IFolderRole[];
}

export interface IFolderCompany{
    name: string;
    companyId: string;
    permissionType: PermissionType
    users: IFolderPermissionUser[]
}

export interface IFolderRole{
    name: string;
    roleId: string;
    permissionType: PermissionType
    users: IFolderPermissionUser[]
}

export type PermissionType = 'viewOnly' | 'viewDownload' | 'uploadOnly' | 'viewDownloadUpload' | 'viewDownloadUploadEdit' | 'fullController';

export interface IFolderPermissionUser{
    name: string;
    email: string;
    permissionType: PermissionType;
}