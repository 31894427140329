import React from 'react';
import {BimFile} from "../Utilities/Classes/BimFile";
import {Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@mui/material";

export const CopyingFilesAlert = ({files}: {files: BimFile[]})=>{

    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Adding selected files..."}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <LinearProgress/>
                    {files.map((file, i)=>(<div key={i}>{file.name}</div>))}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}