import React from 'react';
import {Contents} from "../Utilities/Classes/Contents";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Button} from "@mui/material";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import {FileIcon} from "../Components/FileIcon";
import {BimFile} from "../Utilities/Classes/BimFile";
import {NavLink, useHistory} from 'react-router-dom';
import {useLocation} from "react-router";
import {observer} from "mobx-react";
import {DeleteColumnCell} from "../Components/DeleteColumnCell";
import {Header} from "../Components/Header";

export const FramePage = observer(({contents, ecosysParams}: {contents: Contents, ecosysParams: EcoSysParams})=>{

    const history = useHistory();
    const {search} = useLocation();

    // function deleteFile(e: any, file: BimFile){
    //     e.stopPropagation();
    //
    //     const confirm = window.confirm(`Are you sure you want to delete this file?\n${file.name}`)
    //
    //     if(confirm){
    //         file.delete(ecosysParams)
    //             .then(()=>{
    //                 contents.removeFile(file);
    //             })
    //     }
    // }

    const columns: GridColDef[] = [
        {field: "fileType", width: 50, renderCell: (params => <FileIcon file={params.row as BimFile}/>)},
        {field: "name", headerName: "File Name", flex: 1,
            renderCell: (params: GridCellParams)=>{
                return (<Button style={{justifyContent: "start"}} size={"small"}>{params.value}</Button>)
            }
        },
        {field: "lastUpdateText", headerName: "Last Update", width: 200}
    ]

    if(ecosysParams.isEditable){
        columns.push({field: "itemId", headerName: "Actions", width: 100,
            renderCell: (params: GridCellParams)=>{
                return (<DeleteColumnCell file={params.row as BimFile} contents={contents} ecosysParams={ecosysParams}/>)
            }
        })
    }


    if(contents.files.length === 1){
        history.push(`/single/singleFile/${contents.files[0].itemId}/${search}`)
    }

    return (
            <>
                <Header contents={contents}>
                    {ecosysParams.isEditable ? <NavLink to={`/single/copy${window.location.search}`}>
                        <Button variant={'outlined'}>Add Files</Button>
                    </NavLink>: <></>}
                </Header>

                <main>
                    <DataGrid
                            density={"compact"}
                            rows={contents.files}
                            columns={columns}
                            // autoPageSize={true}
                            onCellClick={(cell)=>{
                                if(cell.colDef.headerName !== 'Actions'){
                                    const bimFile = cell.row as BimFile;
                                    if (bimFile.isForgeViewable || bimFile.isMicrosoftViewable || bimFile.isGoogleViewable || !contents.authorized) {
                                        history.push(`/single/singleFile/${bimFile.itemId}/${search}`)
                                    } else {
                                        bimFile.openInBim360();
                                    }
                                }
                            }}
                    />
                </main>
            </>
    )
});