import {makeAutoObservable} from "mobx";
import {ApiCalls} from "../ApiCalls";

export interface ISettings{
    googleViewerFormats: string[];
    microsoftViewerFormats: string[];
    forgeViewerFormats: string[];
    accountId: string;
}

export class Settings {
    public googleViewerFormats: string[] = [];
    public microsoftViewerFormats: string[] = [];
    public forgeViewerFormats: string[] = [];
    public accountId: string = "";

    constructor() {
        makeAutoObservable(this);
    }


    public load(){
        ApiCalls.Settings.GetViewerSettings()
            .then(settings=>{
                this.setGoogleViewerFormats(settings.googleViewerFormats);
                this.setMicrosoftViewerFormats(settings.microsoftViewerFormats);
                this.setForgeViewerFormats(settings.forgeViewerFormats);
                this.setAccountId(settings.accountId);
            })
    }

    public save(){
        ApiCalls.Settings.SaveViewerSettings({
            googleViewerFormats: this.googleViewerFormats,
            microsoftViewerFormats: this.microsoftViewerFormats,
            forgeViewerFormats: this.forgeViewerFormats,
            accountId: this.accountId
        })
    }

    public setGoogleViewerFormats(formats: string[]){
        this.googleViewerFormats = formats;

        this.microsoftViewerFormats = this.microsoftViewerFormats.filter(x=>formats.indexOf(x) === -1)
        this.forgeViewerFormats = this.forgeViewerFormats.filter(x=>formats.indexOf(x) === -1)
    }
    public setMicrosoftViewerFormats(formats: string[]){
        this.microsoftViewerFormats = formats;

        this.googleViewerFormats = this.googleViewerFormats.filter(x=>formats.indexOf(x) === -1)
        this.forgeViewerFormats = this.forgeViewerFormats.filter(x=>formats.indexOf(x) === -1)
    }
    public setForgeViewerFormats(formats: string[]){
        this.forgeViewerFormats = formats;

        this.googleViewerFormats = this.googleViewerFormats.filter(x=>formats.indexOf(x) === -1)
        this.microsoftViewerFormats = this.microsoftViewerFormats.filter(x=>formats.indexOf(x) === -1)
    }
    public setAccountId(accountId: string){
        this.accountId = accountId;
    }
}