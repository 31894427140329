import React, {useEffect, useState} from 'react';
import {Contents} from "../Utilities/Classes/Contents";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Button} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";
import { ProjectFileTreeView } from '../Components/ProjectFileTreeView';
import {appState} from "../Utilities/AppState";
import {observer} from "mobx-react";
import {ApiCalls} from "../Utilities/ApiCalls";
import {ActionButton} from "../Components/ActionButton";
import {Header} from "../Components/Header";
import {BimFile} from "../Utilities/Classes/BimFile";
import {CopyingFilesAlert} from "../Components/CopyingFilesAlert";

export const AddFilePage = observer(({contents, ecoSysParams}: {contents: Contents, ecoSysParams: EcoSysParams})=> {

    const {search} = useLocation();
    const [expandedFolderIds, setExpandedFolderIds] = useState<string[]>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [copying, setCopying] = useState(false);
    const [filesToCopy, setFilesToCopy] = useState<BimFile[]>();


    useEffect(()=>{
        appState.loadTopFolders()
            .then(()=>{
                setExpandedFolderIds(appState.topFolders.map(x=>x.id))
            })
    }, [])

    function copySelected(){
        if(selectedFileIds.length){
            setCopying(true)
            //Get Files
            const files = appState.flatFiles.filter(x=>selectedFileIds.indexOf(x.id) > -1);
            setFilesToCopy(files);
console.log("FILES", files)
            if(files.length){
                setCopying(true)
                ApiCalls.CopyFilesToFolder({ ecoSysParams, files: files} )
                    .then(()=>{
                        window.location.assign(`/${window.location.search}`);
                    })
                    .catch((e)=>{
                        appState.addError(e);
                        console.log("COPY FILES ERROR", e)
                        setCopying(false);
                    })
            }
        }
        console.log("SELECTED FILE IDS", selectedFileIds)
    }

    return (
        <>
            <Header contents={contents}/>

            <main>
                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{display: "flex", flexDirection: 'column', width: '200px', padding: '5px'}}>
                        <ActionButton running={copying} variant={'contained'} onClick={()=>copySelected()}>Add Selected Files</ActionButton>
                        <NavLink style={{display: 'flex'}} to={`/${search}`}><Button style={{flex: 1}}>Cancel</Button></NavLink>

                        <div style={{flex: 1}}/>

                    </div>

                    <div style={{flex: 1, padding: '5px'}}>
                        <ProjectFileTreeView
                            loading={appState.loading}
                            topFolders={appState.topFolders}
                            expandedFolderIds={expandedFolderIds}
                            setExpandedFolderIds={setExpandedFolderIds}
                            selectedFileIds={selectedFileIds}
                            setSelectedFileIds={setSelectedFileIds}
                        />
                    </div>

                </div>
            </main>

            {copying && filesToCopy ? <CopyingFilesAlert files={filesToCopy!}/> : <></>}
        </>
    )
});