import React, {useMemo, useState} from 'react';
import {observer} from "mobx-react";
import {TreeItem, TreeView} from "@mui/lab";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderIcon from "@mui/icons-material/Folder";
import {BatchContents, BatchData} from "../Utilities/Classes/BatchContents";
import {FileIcon} from "./FileIcon";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Button, Checkbox, Typography} from "@mui/material";
import {BatchFile} from "../Utilities/Classes/BatchFile";
import {MassUpdateFileMenuButton} from "./MassUpdateFileMenu";

export const MassUpdateTree = observer(({ filter, selected, setSelected, contents, ecosysParams, setAnchorEl, setFile }
                                            : { filter?: boolean, setAnchorEl: (x?: Element)=>void, setFile : (x?: BatchFile)=>void, selected: string[], setSelected: (x: string[])=>void, contents: BatchContents, ecosysParams: EcoSysParams })=>{

    function clickTreeItem(node: BatchData){
        if(node.fileData && node.fileData.behindSource) {
            const newSelected = selected.indexOf(node.id) > -1 ? selected.filter(x=>x !== node.id) : selected.concat([node.id])
            setSelected(newSelected)
        }
    }

    const renderTree = (node: BatchData) => (
        <TreeItem
            icon={node.fileData ? <FileIcon file={node.fileData}/> : undefined}
            key={node.id}
            nodeId={node.id}
            label={node.fileData ?
                <FileNode file={node.fileData} checked={selected.indexOf(node.id) > -1} setAnchorEl={setAnchorEl} setFile={setFile}/> :
                <FolderNode selected={selected} setSelected={setSelected} data={node}/>
                }
            onClick={()=>clickTreeItem(node)}
        >
            {Array.isArray(node.children)
                ? node.children
                    .filter(x=>filter && x.fileData ? x.fileData.behindSource : true)
                    .map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    return contents.files.length ? (
        <>
            <BatchTreeHeader ecosysParams={ecosysParams}/>
            <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<FolderOpenIcon />}
                defaultExpanded={buildDefaultExpanded(contents.root)}
                defaultExpandIcon={<FolderIcon />}
            >
                {contents.root?.children
                    ?.filter(x=>filter && x.fileData ? x.fileData.behindSource : true)
                    .map((node) => renderTree(node))}
            </TreeView>
        </>
    ):  (<div style={{display: 'flex', height: '50vh', justifyContent: 'center', alignItems: 'center'}}>
        <Typography>No files available</Typography>
    </div>)
})

const BatchTreeHeader = ({ecosysParams}: {ecosysParams: EcoSysParams}) =>{
    const ancestors = ecosysParams.PathNameAncestors;
    const [show, setShow] = useState(false)

    return(
        <div style={{display: 'flex', alignItems: 'baseline'}}>
            <h3>{ancestors[1]}</h3>
            {show ? <div style={{marginLeft: '20px'}}>{[ancestors[0]].concat(ancestors.slice(2)).join(' / ')}</div> : <></>}
            <Button size={'small'} onClick={()=>setShow(!show)}>{show ? <>&lt;</> : <>&gt;</>}</Button>
            <div style={{flex: 1}}/>
        </div>
    )
};

function buildDefaultExpanded(nodes: BatchData) {

    if (!nodes)
        return [];

    var ids = [nodes.id]

    if (nodes.children) {
        for (let i = 0; i < nodes.children.length; i++) {
            var childIds = buildDefaultExpanded(nodes.children[i])
            if (childIds)
                childIds.map(id => ids.push(id));
        }
    }
    return ids;
}


const FolderNode = observer(({setSelected, selected, data}: {selected: string[], setSelected: (x: string[])=>void, data: BatchData})=>{

    const checked: 'checked' | 'indeterminate' | undefined = useMemo(()=>{
        const selectedSet = new Set(selected);
        const childFiles = data.childFiles.filter(x=>x.behindSource);
        let current: 'checked' | 'indeterminate' | 'notChecked' | undefined = undefined;

        for(let i = 0; i < childFiles.length; i++){
            const file = childFiles[i]
            if(file.batchId && selectedSet.has(file.batchId)){
                if(current === 'notChecked'){
                    current = 'indeterminate';
                    break;
                } else {
                    current = 'checked';
                }
            } else if(current === 'checked'){
                current = 'indeterminate';
                break;
            } else {
                current = 'notChecked';
            }
        }

        if(current === 'notChecked') return undefined;

        return current;
    }, [selected])

    const behindSource: boolean = useMemo(()=>{

        for(let i = 0; i < data.childFiles.length; i++){
            if(data.childFiles[i].behindSource) return true;
        }
        return false;
    }, [data])

    function handleClick(e: any){
        e.stopPropagation();

        if(checked === 'checked'){
            const selectedSet = new Set(selected);
            data.childIds.forEach(x=>selectedSet.delete(x));
            setSelected(Array.from(selectedSet));
        } else {
            const newSelected = Array.from(new Set(data.childIds.concat(selected)));
            setSelected(newSelected);
        }
    }

    return (
        <div style={{display: "flex", alignItems: 'center'}}>
            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                <Checkbox
                    disabled={!behindSource}
                    checked={(checked === 'checked' || checked === 'indeterminate') && behindSource}
                    indeterminate={checked === 'indeterminate'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onClick={handleClick}
                />
                <div style={{color: behindSource ? undefined : '#aaa', whiteSpace: 'nowrap', paddingRight: '1em', flex: 1, overflow: 'hidden', textOverflow: "ellipsis"}}>
                    {data.name}
                </div>
            </div>
            <div className={'nodeButton'}  style={{width: '240px'}}>

            </div>

        </div>
    )
});

const FileNode = observer(({file, checked, setAnchorEl, setFile}: {file: BatchFile, checked: boolean, setAnchorEl: (x?: Element)=>void, setFile : (x?: BatchFile)=>void, })=>{

    if(file.fileType === 'empty'){
        return (
            <div style={{display: "flex", alignItems: 'baseline'}}>
                <div style={{flex: 1}}>{file.name}</div>
            </div>)
    }

    return (
        <div style={{display: "flex", alignItems: 'center'}}>
            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                <Checkbox
                    disabled={!file.behindSource}
                    checked={file.behindSource && checked}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <div style={{color: file.behindSource ? undefined : '#aaa', whiteSpace: 'nowrap', paddingRight: '1em', flex: 1, overflow: 'hidden', textOverflow: "ellipsis"}}>
                    {file.name}
                </div>
            </div>

            <div className={'nodeButton'}  style={{width: '240px'}}>
                {file.behindSource === undefined ? <span style={{color: 'purple'}}>Unlinked from Source File</span> : <></>}
                {file.behindSource === true ? <span style={{color: 'red'}}>Out of Date</span> : <></>}
                {file.behindSource === false ? <span>Up to Date</span> : <></>}
            </div>

            <div className={'nodeButton'} style={{width: '200px'}}>
                <MassUpdateFileMenuButton file={file} setAnchorEl={setAnchorEl} setMenuFile={setFile}/>
            </div>

        </div>
    )
});