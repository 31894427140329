import {IToken} from "./Classes/Token";
import axios from 'axios';
import {BimFile, IBimFile} from "./Classes/BimFile";
import {EcoSysParams} from "./Classes/EcoSysParams";
import {IContentsResponse} from "./Classes/Contents";
import {IFolder} from "./Classes/Folder";
import { IBatchContentsResponse } from "./Classes/BatchContents";
import {BatchFile} from "./Classes/BatchFile";
import {ISettings} from "./Classes/Settings";

export class ApiCalls{
    static Logout(): Promise<void>{
        return SimplePost(`/api/auth/logout`);
    }
    static GetToken(): Promise<IToken>{
        return SimpleGet(`/api/auth/viewerToken`);
    }

    static GetLoginUrl(): Promise<string>{
        return SimpleGet(`/api/forge/oauth/url?encodedRedirectUrl=${btoa(`${window.location.origin}/close`)}`);
    }

    static GetFrame({ ecoSysParams }: { ecoSysParams: EcoSysParams }) {
        return SimpleGet<IContentsResponse>(`/api/contents?${ecoSysParams.searchString}`)
    }

    static GetTree({ ecoSysParams, isMassUpdate }: { ecoSysParams: EcoSysParams, isMassUpdate?: boolean }) {
        return SimpleGet<IBatchContentsResponse>(`/api/contents/batch?${isMassUpdate ? 'isMassUpdate=true&' : ''}${ecoSysParams.searchString}`)
    }

    static GetTopFolders({projectId, ecoSysParams}: {projectId: string, ecoSysParams: EcoSysParams}){
        return SimpleGet<IFolder[]>(`/api/forge/${projectId}/topFolders?${ecoSysParams.searchString}`)
    }

    static GetFolderContents({projectId, folderId, ecoSysParams}: {projectId: string, folderId: string, ecoSysParams: EcoSysParams}){
        return SimpleGet<{folders: IFolder[], files: IBimFile[]}>(`/api/forge/${projectId}/folders/${folderId}?${ecoSysParams.searchString}`)
    }

    static CopyFilesToFolder({ecoSysParams, files}: {ecoSysParams: EcoSysParams, files: BimFile[]}){
        return SimplePost(`/api/forge/${ecoSysParams.ProjectId}/copyFiles?${ecoSysParams.searchString}`, files.map(x=>x.interface))
    }

    static UpdateFiles({ecoSysParams, files}: {ecoSysParams: EcoSysParams, files: BatchFile[]}){
        return SimplePost(`/api/forge/${ecoSysParams.ProjectId}/updateFiles?${ecoSysParams.searchString}`, files.map(x=>x.interface))
    }

    static DeleteFile({ecoSysParams, file}: {ecoSysParams: EcoSysParams, file: BimFile}){
        return SimpleDelete(`/api/forge/${ecoSysParams.ProjectId}/items/${file.itemId}?${ecoSysParams.searchString}`)
    }

    static Settings = class {
        static GetViewerSettings(){
            return SimpleGet<ISettings>(`/api/settings/viewers`)
        }
        static SaveViewerSettings(settings: ISettings){
            return SimplePost<ISettings>(`/api/settings/viewers`, settings)
        }
    }
}





function SimplePost<T>(url: string, body?: any, options?: any): Promise<T> {
    return new Promise((resolve, reject) => {
        axios.post(url, body, options)
            .then(result => {
                resolve(result?.data)
            })
            .catch(error => {
                reject(error);
            })
    })
}

function  SimpleGet<T>(url: string, options?: any): Promise<T>{
    return new Promise((resolve, reject) => {
        axios.get(url, options)
            .then(result => {
                resolve(result?.data)
            })
            .catch(error => {
                reject(error);
            })
    })
}

// function SimplePatch<T>(url: string, body?: any, options?: any): Promise<T> {
//     return new Promise((resolve, reject) => {
//         axios.patch(url, body, options)
//             .then(result => {
//                 resolve(result?.data)
//             })
//             .catch(error => {
//                 reject(error);
//             })
//     })
// }
//
function SimpleDelete<T>(url: string, options?: any): Promise<T> {
    return new Promise((resolve, reject) => {
        axios.delete(url, options)
            .then(result => {
                resolve(result?.data)
            })
            .catch(error => {
                reject(error);
            })
    })
}