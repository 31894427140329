import React from 'react';
import {BatchContents} from "../Utilities/Classes/BatchContents";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import {FileIcon} from "../Components/FileIcon";
import {BatchFile} from "../Utilities/Classes/BatchFile";
import {NavLink, useHistory} from 'react-router-dom';
import {useLocation} from "react-router";
import {observer} from "mobx-react";
import {Header} from "../Components/Header";
import {BatchTree} from "../Components/BatchTree";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                //@ts-ignore
                <Box sx={{ p: 3 }} {...other}>
                    <Typography {...other}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const BatchPage = observer(({ contents, ecosysParams }: { contents: BatchContents, ecosysParams: EcoSysParams }) => {

    const history = useHistory();
    const { search } = useLocation();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function setHiddenColumns(colDefs: any){
        const object = Object.keys(colDefs).reduce((acc: any, val)=>{
            const def = colDefs[val];
            acc[def.field] = def.hide;
            return acc;
        }, {})

        window.localStorage.setItem('columnState', JSON.stringify(object));
    }

    const columnState = JSON.parse(window.localStorage.getItem('columnState') ?? "{}");

    const columns: GridColDef[] = [
        { field: "fileType", hide: columnState["fileType"] ?? false, width: 50, renderCell: (params => <FileIcon file={params.row as BatchFile} />) },
        {
            field: "name", headerName: "File Name", flex: 1,
            renderCell: (params: GridCellParams) => {
                return (<Button style={{ justifyContent: "start" }} size={"small"} >{params.value}</Button>)
            }
        },
        {field: "0", hide: columnState["0"] ?? false, width: 150, headerName: "Phase",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "1", hide: columnState["1"] ?? false, width: 150, headerName: "Basis",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "2", hide: columnState["2"] ?? false, width: 150, headerName: "Category",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "3", hide: columnState["3"] ?? false, width: 150, headerName: "Description",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        {field: "4", hide: columnState["4"] ?? false, width: 150, headerName: "Header",
            valueGetter: (params)=>params.row.paths[params.field],
            renderCell: x=>(<div title={`${x.value}`}>{x.value}</div>)},
        { field: "lastUpdateText", hide: columnState["lastUpdateText"] ?? false, headerName: "Last Update", width: 200 }
    ]


    if (contents.files.length === 1) {
        history.push(`/batch/singleFile/${contents.files[0].itemId}/${search}`)
    }

    return (
        <>
            <Header contents={contents}>
                {ecosysParams.isEditable ? <NavLink to={`/single/copy${window.location.search}`}>
                </NavLink> : <></>}
            </Header>

            <main style={{ display: 'flex', flexDirection: 'column' }}>
                {/*@ts-ignore*/}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {/*@ts-ignore*/}
                    <Tabs value={value} onChange={handleChange} aria-label="Tree and List View Tabs">
                        <Tab label="Tree View" {...a11yProps(0)} />
                        <Tab label="List View" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <BatchTree contents={contents} ecosysParams={ecosysParams}/>
                </TabPanel>
                {/*@ts-ignore*/}
                <TabPanel style={{ flex: 1, height: '100%' }} value={value} index={1}>
                    <DataGrid
                        density={"compact"}
                        rows={contents.files}
                        columns={columns}
                        // autoPageSize={true}
                        onStateChange={(e)=>setHiddenColumns(e.state.columns.lookup)}
                        onCellClick={(cell) => {
                            if (cell.colDef.headerName !== 'Actions') {
                                const bimFile = cell.row as BatchFile;
                                if (bimFile.isForgeViewable || bimFile.isMicrosoftViewable || bimFile.isGoogleViewable || !contents.authorized) {
                                    history.push(`/batch/singleFile/${bimFile.itemId}/${search}`)
                                } else {
                                    bimFile.openInBim360();
                                }
                            }
                        }}
                    />
                </TabPanel>
            </main>
        </>
    )
})