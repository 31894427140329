import React, {useMemo, useState} from 'react';
import {observer} from "mobx-react";
import {Contents} from "../Utilities/Classes/Contents";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Button, Typography} from "@mui/material";
import {NavLink, useParams} from "react-router-dom";
import {ForgeViewable} from "../Components/ForgeViewable";
import {NonViewable} from "../Components/NonViewable";
import {BimFile} from "../Utilities/Classes/BimFile";
import {Header} from "../Components/Header";
import {DeleteFileAlert} from "../Components/DeleteFileAlert";
import {OfficeViewable} from "../Components/OfficeViewable";
import {useIsSingle} from "../Utilities/Hooks/useIsSingle";
import {GoogleViewable} from "../Components/GoogleViewable";

export const SingleFilePage = observer(({contents, ecosysParams}: {contents: Contents, ecosysParams: EcoSysParams})=>{

    const isSingle = useIsSingle();
    const {itemId} = useParams<{itemId: string}>();

    const file = useMemo(()=>{
        return contents.files.find(x=>x.itemId === itemId);
    }, [contents, itemId])

    const [fileToDelete, setFileToDelete] = useState<BimFile>()

    function deleteFile(file: BimFile){
        setFileToDelete(file);
    }

    function getViewable(){
        if(file?.isForgeViewable) return <ForgeViewable file={file} contents={contents}/>
        else if (file?.isMicrosoftViewable) return <OfficeViewable file={file}/>
        else if (file?.isGoogleViewable) return <GoogleViewable file={file}/>
        else if (file) return <NonViewable file={file} contents={contents}/>
        else return <Typography>No files to display</Typography>
    }

    const currentFileIndex = contents.files.findIndex(x=>file?.id === x.id);
    const previousFile = contents.files[currentFileIndex - 1];
    const nextFile = contents.files[currentFileIndex + 1];

    return (
        <>
            <Header
                leftButtons={contents.files.length > 1 ? <>
                    <NavLink to={`/${isSingle ? 'single' : 'batch'}/${window.location.search}`}>
                        <Button>Back to List</Button></NavLink>
                    <NavLink to={`/${isSingle ? 'single' : 'batch'}/singleFile/${previousFile?.itemId}/${window.location.search}`}
                    ><Button disabled={!previousFile}>Prev File</Button></NavLink>
                    <NavLink to={`/${isSingle ? 'single' : 'batch'}/singleFile/${nextFile?.itemId}/${window.location.search}`}
                    ><Button disabled={!nextFile}>Next File</Button></NavLink>

                </>: <></>}
                contents={contents}
            >
                <Button
                    variant={'outlined'}
                    onClick={()=>window.open(window.location.href, '_blank')}
                >Open in New Window</Button>

                {contents.authorized && ecosysParams.isEditable && isSingle && file ?
                    <Button variant={'outlined'} onClick={()=>deleteFile(file)}>Remove File</Button>
                    : <></>}

                {ecosysParams.isEditable && isSingle ? <NavLink to={`/${isSingle ? 'single' : 'batch'}/copy${window.location.search}`}>
                    <Button variant={'outlined'}>Add Files</Button>
                </NavLink>: <></>}
            </Header>

            <main>
                {getViewable()}
            </main>

            {fileToDelete ? <DeleteFileAlert
                file={fileToDelete}
                ecosysParams={ecosysParams}
                contents={contents}
                handleClose={()=>setFileToDelete(undefined)}
            /> : <></>}
        </>
    )
})