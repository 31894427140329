import React, {useState} from 'react';
import {BimFile} from "../Utilities/Classes/BimFile";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {Contents} from "../Utilities/Classes/Contents";
import {DeleteFileAlert} from "./DeleteFileAlert";
import {Button} from "@mui/material";

export const DeleteColumnCell = ({file, contents, ecosysParams}: {file: BimFile, contents: Contents, ecosysParams: EcoSysParams})=>{

    const [fileToDelete, setFileToDelete] = useState<BimFile>()

    function deleteFile(e: any){
        e.stopPropagation();
        setFileToDelete(file);
    }

    return (
        <>
            <Button onClick={deleteFile} size={"small"} >
                Remove File
            </Button>

            {fileToDelete ? <DeleteFileAlert
                file={fileToDelete}
                ecosysParams={ecosysParams}
                contents={contents}
                handleClose={()=>setFileToDelete(undefined)}
            /> : <></>}
        </>
    )
}