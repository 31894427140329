import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import {Contents} from "./Contents";
import {ApiCalls} from "../ApiCalls";
import {EcoSysParams} from "./EcoSysParams";
import {appState} from "../AppState";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

export interface IBimFile {
    name: string;
    itemId: string;
    objectId: string;
    versionId: string;
    derivativeId: string;
    fileType: string;
    version: number;
    lastUpdate: string;
    url: string;
    s3Url: string;
}

export class BimFile {
    public name: string;
    public itemId: string;
    public objectId: string;
    public versionId: string;
    public derivativeId: string;
    public fileType: string;
    public hubId: string;
    public projectId: string;
    public folderId: string;
    public version: number;
    public url: string;
    public s3Url: string;
    public lastUpdate?: Dayjs;
    public contents: Contents;

    constructor(props: IBimFile, contents: Contents, folderId?: string) {
        this.name = props.name;
        this.itemId = props.itemId;
        this.objectId = props.objectId;
        this.versionId = props.versionId;
        this.url = props.url;
        this.s3Url = encodeURIComponent(props.s3Url ?? "");
        this.derivativeId = `urn:${btoa((this.versionId) || "")}`;;
        this.fileType = props.fileType;
        this.version = props.version;
        this.hubId = contents.hubId;
        this.projectId = contents.projectId;
        this.folderId = folderId ?? contents.folderId;
        this.lastUpdate = props.lastUpdate ? dayjs.utc(props.lastUpdate).tz(dayjs.tz.guess()) : undefined;
        this.contents = contents;
    }

    public get interface(){
        return {
            name: this.name,
            itemId: this.itemId,
            objectId: this.objectId,
            versionId: this.versionId,
            derivativeId: this.derivativeId,
            fileType: this.fileType,
            hubId: this.hubId,
            projectId: this.projectId,
            folderId: this.folderId,
            version: this.version,
            url: this.url,
            lastUpdate: this.lastUpdate
        }
    }

    public get isForgeViewable(){
        return appState.settings.forgeViewerFormats.indexOf(this.fileType) > -1;
    }

    public get isMicrosoftViewable(){
        return appState.settings.microsoftViewerFormats.indexOf(this.fileType.toLowerCase()) > -1;
    }

    public get isGoogleViewable(){
        return appState.settings.googleViewerFormats.indexOf(this.fileType.toLowerCase()) > -1;
    }

    public get id(){return this.versionId}

    public openInBim360(){
        window.open(this.fileUrl, '_blank')
    }

    public delete(ecoSysParams: EcoSysParams){
        return ApiCalls.DeleteFile({ecoSysParams, file:this})
    }

    public get fileUrl(){
        return this.url;
    }

    public get downloadUrl(){
        return `/api/forge/${appState.ecoSysParams?.ProjectId}/items/${this.itemId}/download/${this.name}?${appState.ecoSysParams!.searchString}`
    }

    public embedViewerUrl({type}: {type: 'office' | 'google'}){
        const useS3 = window.localStorage.getItem('useS3')


        switch(type){
            case 'office': return `https://view.officeapps.live.com/op/embed.aspx?src=${this.officeDownloadUrl}`;
            case 'google': return `https://docs.google.com/viewer?url=${this.s3Url}&embedded=true`;
        }
    }

    public get officeDownloadUrl(){
        const token = document.cookie.split(';').map(x=>x.trim().split('=')).find(x=>x[0] === 'bim360-ecosys-integration')?.pop();
        const path = `${document.location.origin}/api/forge/${appState.ecoSysParams?.ProjectId}/items/${this.itemId}/officeDownload/${token}/${this.name}`;
        return encodeURIComponent(path);
    }

    // public get googleDownloadUrl(){
    //     const token = document.cookie.split(';').map(x=>x.trim().split('=')).find(x=>x[0] === 'bim360-ecosys-integration')?.pop();
    //     return `${document.location.origin}/api/forge/${appState.ecoSysParams?.ProjectId}/items/${this.itemId}/officeDownload/${token}/${this.name}`;
    // }

    public get lastUpdateText(){return this.lastUpdate?.calendar()}

    public get viewerUrl(){
        return `/single/singleFile/${this.itemId}?${this.contents.ecoSysParams.interface}`
    }
    public get massUpdateViewerUrl(){
        return `/massUpdate/singleFile/${this.itemId}?${this.contents.ecoSysParams.interface}`
    }
    public get massUpdateViewerSourceUrl(){
        return `/massUpdate/singleFile/${this.itemId}/source?${this.contents.ecoSysParams.interface}`
    }
}