import React, {useState} from 'react';
import {BimFile} from "../Utilities/Classes/BimFile";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import {Contents} from "../Utilities/Classes/Contents";
import {EcoSysParams} from "../Utilities/Classes/EcoSysParams";
import {ActionButton} from "./ActionButton";

export const DeleteFileAlert = ({file, handleClose, contents, ecosysParams}: {file: BimFile, contents: Contents, ecosysParams: EcoSysParams, handleClose: ()=>void})=>{

    const {search} = useLocation();
    const history = useHistory();

    const [deleting, setDeleting] = useState(false)

    function deleteFile(){
        setDeleting(true)
        file.delete(ecosysParams)
                .then(()=>{
                    contents.removeFile(file);
                    handleClose();
                    history.push(`/${search}`);
                })
    }

    return (
            <Dialog
                    open={true}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"This will remove the file from this gate package. This will not delete the file from its working location. Are you sure you want to continue?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{marginBottom: '1.5em'}}>

                        </div>
                        <div style={{textAlign: 'center'}}>
                            {file.name}

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <ActionButton running={deleting} color="primary" onClick={deleteFile} autoFocus>
                        Remove File
                    </ActionButton>
                </DialogActions>
            </Dialog>
    );
}