import {TreeItem, TreeView} from "@mui/lab";
import {BimFile} from "../Utilities/Classes/BimFile";
import {Folder} from "../Utilities/Classes/Folder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Button, Checkbox, Paper} from "@mui/material";
import React, {useMemo} from "react";
import {appState} from "../Utilities/AppState";
import {observer} from "mobx-react";
import {LoadingPage} from "../Pages/LoadingPage";
import {FileIcon} from "./FileIcon";

interface TreeNode{
    id: string;
    name: string;
    type?: "file" | "folder";
    children?: TreeNode[];
}

export const ProjectFileTreeView = observer(({loading, topFolders, expandedFolderIds, setExpandedFolderIds, selectedFileIds, setSelectedFileIds, filteredFiles}:
                                                 {loading: boolean, topFolders: Folder[], expandedFolderIds: string[], setExpandedFolderIds: Function, selectedFileIds: string[], setSelectedFileIds: Function, filteredFiles?: BimFile[]})=>{


    if(loading) return <LoadingPage/>


    if(topFolders.length === 0) return <NoFolders/>;

    return (
        <Paper style={{height: '100%', display: 'flex', flexDirection: 'column', padding: '10px'}}>
            <div style={{display: "flex", alignItems: 'baseline', fontWeight: 'bold'}}>

                <div style={{flex: 1}}>Name</div>
                <div className={'nodeButton'} style={{width: '240px'}}>
                    Last Update
                </div>
                <div className={'nodeButton'} style={{width: '160px'}}>

                </div>

            </div>

            <div style={{flex: 1, overflowY: 'auto'}}>

                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultEndIcon={<ExpandMoreIcon/>}
                    multiSelect={true}
                    selected={selectedFileIds}
                    onNodeSelect={(e, selectedIds)=>{
                        //If single clicking, add to the existing list rather than replacing it.
                        let newSelectedFileIds = [];
                        if(selectedIds.length > 1){
                            newSelectedFileIds = selectedIds
                        } else {
                            const singleId = selectedIds[0];
                            if(selectedFileIds.indexOf(singleId) > -1){
                                newSelectedFileIds = selectedFileIds.filter(x=>x !== singleId);
                            } else {
                                newSelectedFileIds = selectedFileIds.concat([singleId]);
                            }
                        }

                        //Only add files, not folders
                        setSelectedFileIds(newSelectedFileIds.filter(x=>x.startsWith("urn:adsk.wipprod:fs.file")))
                    }}
                    expanded={expandedFolderIds}
                    onNodeToggle={(e,folderIds)=>{
                        const folderIdToExpand = folderIds.filter(x=>expandedFolderIds.indexOf(x) === -1).pop();
                        const folderToExpand = appState.flatFolders.find(x=>x.folderId === folderIdToExpand);
                        folderToExpand?.load();

                        setExpandedFolderIds(folderIds)
                    }}
                >
                    {topFolders.map(folder=><TreeFolder key={folder.id} folder={folder} selectedFileIds={selectedFileIds}/>)}
                </TreeView>
            </div>
        </Paper>
    )
});

const TreeFolder = observer(({folder, selectedFileIds}: {folder: Folder, selectedFileIds: string[]})=>{

    return (
        <TreeItem key={folder.id} nodeId={folder.id} label={<FolderNode folder={folder}/>}>
            {folder.childFolders.map(x=><TreeFolder folder={x} key={x.folderId} selectedFileIds={selectedFileIds}/>)}
            {folder.childFiles.map(x=><TreeFile file={x} key={x.itemId} selectedFileIds={selectedFileIds}/>)}
        </TreeItem>
    )
})
const TreeFile = observer(({file, selectedFileIds}: {file: BimFile, selectedFileIds: string[]})=>{

    const checked = useMemo(()=>{
        return selectedFileIds.indexOf(file.id) > -1;
    }, [selectedFileIds, file.id]);

    return <TreeItem style={{marginLeft: '20px'}} icon={<FileIcon file={file}/>} key={file.id} nodeId={file.id} label={<FileNode file={file} checked={checked}/>}/>
})

const NoFolders = ()=>{
    return (
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
            <h4>No Folders Found</h4>
            <p>If you do not have access, please contact the Project Administrator.</p>
        </div>
    )
}

const FolderNode = observer(({folder}: {folder: Folder})=>{

    return (
        <div style={{display: "flex", alignItems: 'baseline'}}>
            <div style={{flex: 1}}>{folder.name}</div>
        </div>
    )
})

const FileNode = observer(({file, checked}: {file: BimFile, checked: boolean})=>{

    function handleOpenInBim360(e: any){
        e.preventDefault();
        e.stopPropagation();

        file.openInBim360();
    }

    if(file.fileType === 'empty'){
        return (
            <div style={{display: "flex", alignItems: 'baseline'}}>
                <div style={{flex: 1}}>{file.name}</div>
            </div>)
    }

    return (
        <div style={{display: "flex", alignItems: 'center'}}>
            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                <Checkbox
                    checked={checked}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <div style={{whiteSpace: 'nowrap', paddingRight: '1em', flex: 1, overflow: 'hidden', textOverflow: "ellipsis"}}>
                    {file.name}
                </div>
            </div>
            <div className={'nodeButton'}  style={{width: '240px'}}>

                {file.lastUpdateText}
            </div>

            <div className={'nodeButton'} style={{width: '160px'}}>
                <Button variant={"outlined"} size={"small"} onClick={handleOpenInBim360}>Open in Build</Button>
            </div>

        </div>
    )
});