import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SingleApp from './SingleApp';
import BatchApp from './BatchApp';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {ClosePage} from "./Pages/ClosePage";
import {ErrorAlert} from "./Components/ErrorAlert";
import {MassUpdateApp} from "./MassUpdateApp";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorAlert/>
            <Switch>
                <Route exact path={"/close"} component={ClosePage}/>
                <Route path={"/single"} component={SingleApp}/>
                <Route path={"/batch"} component={BatchApp}/>
                <Route path={"/massUpdate"} component={MassUpdateApp}/>
                <Redirect path={"/debug"} to={`/single?ProjectId=PRJ-E104623&PROJECTNAME=Document%20Management&ECOSYSAREA=DELDOC&PHASE=P5&ACCESS=E&HEADERID=363221%20-%20A04.003%20Project%20Charter&PACKAGEFLAG=false&PATHNAMES=Phase%205%20-%20Construction/1%20Basis%20of%20Project%20Decision%20%28BEAM%29/A%200-%20Project%20Alignment/Alternative%20Analysis&ECOSYSENV=georgiapacificepc-test`}/>
                <Redirect path={"/"} to={`/single${window.location.search.replace('(', '%28').replace(')', '%29')}`}/>
            </Switch>
        </BrowserRouter>

        <small style={{position: "fixed", left: "5px", bottom: '5px'}}>{process.env.REACT_APP_VERSION}</small>
    </React.StrictMode>,
    document.getElementById('root')
);