import {Contents} from "./Classes/Contents";
import {Folder} from "./Classes/Folder";
import {EcoSysParams} from "./Classes/EcoSysParams";
import {makeAutoObservable} from "mobx";
import {ApiCalls} from "./ApiCalls";
import {BimFile} from "./Classes/BimFile";
import {Settings} from "./Classes/Settings";

export class AppState{
    public contents?: Contents;
    public ecoSysParams?: EcoSysParams;
    public loading: boolean = true;
    public flatFolders: Folder[] = [];
    public flatFiles: BimFile[] = [];
    public topFolders: Folder[] = [];
    public errors: string[] = [];

    public settings: Settings = new Settings();

    constructor() {
        makeAutoObservable(this);
        this.loadTopFolders();
        this.settings.load();
    }

    public loadTopFolders(){
        return new Promise<void>((resolve)=>{
            if(this.ecoSysParams){
                this.loading = true;
                ApiCalls.GetTopFolders({projectId: this.ecoSysParams.ProjectId, ecoSysParams: this.ecoSysParams})
                    .then(iFolders=>{
                        const folders = iFolders.map(x=>new Folder(x))
                            .sort((a,b)=>a.name.localeCompare(b.name, 'en', {numeric: true}));
                        this.flatFolders = folders;
                        this.topFolders = folders;
                        this.loading = false;

                        folders.forEach(x=>x.load());
                        resolve();
                    })
            }
        })
    }

    public addError(error: string){
        this.errors = this.errors.concat([error])
    }
    public clearErrors(){
        this.errors = [];
    }

    public addFolders(folders: Folder[]){
        this.flatFolders = this.flatFolders.concat(folders);
    }

    public addFiles(files: BimFile[]){
        this.flatFiles = this.flatFiles.concat(files);
    }

    public setLoading(state: boolean){
        this.loading = state;
    }

    public setContents(contents: Contents){
        this.contents = contents;
    }

    public setEcoSysParams(ecoSysParams: EcoSysParams){
        this.ecoSysParams = ecoSysParams;
    }
}

export const appState = new AppState();