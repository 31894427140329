export interface IEcoSysParams {
    projectid: string;
    projectname: string;
    ecosysarea: 'BGTDOC' | 'CHGDOC' | 'DELDOC' | 'PLLDOC' | 'RSKDOC';
    phase: string;
    headerid: string;
    access: 'R' | 'E';
    pathnames: string;
    ecosysenv: string;
    packageflag: boolean;

}

export class EcoSysParams {
    public ProjectId: string;
    public ProjectName: string;
    public EcoSysArea: 'BGTDOC' | 'CHGDOC' | 'DELDOC' | 'PLLDOC' | 'RSKDOC';
    public Header: string;
    public Phase: string;
    public Rule: 'R' | 'E';
    public PathNames: string;
    public EcoSysEnv: string;
    public PackageFlag: boolean;

    constructor(params: IEcoSysParams) {
        this.ProjectId = params.projectid;
        this.ProjectName = params.projectname;
        this.Phase = params.phase;
        this.Header = params.headerid;
        this.EcoSysArea = params.ecosysarea;
        this.Rule = params.access;
        this.PathNames = params.pathnames ?? "";
        this.EcoSysEnv = params.ecosysenv;
        this.PackageFlag = params.packageflag;
    }

    public get isEditable(): boolean{
        return this.Rule === 'E';
    }

    public get PathNameAncestors(){
        return this.PathNames.split('/').map(x=>decodeURIComponent(x))
    }

    public get isValid(){
        return (
            this.ProjectId
            && this.ProjectName
            && this.EcoSysArea
            // && this.Header
            && this.Rule
            // && this.EcoSysEnv
        )
    }

    public get isValidForMassUpdate(){
        return (
            this.ProjectId
            && this.ProjectName
            && this.EcoSysArea
            && this.Phase
            && this.Rule
            && this.EcoSysEnv
        )
    }

    public get interface(){
        return [
            ["projectid", this.ProjectId],
            ["projectname", this.ProjectName],
            ["ecosysarea", this.EcoSysArea],
            ["phase", this.Phase],
            ["headerid", this.Header],
            ["access", this.Rule],
            ["pathnames", this.PathNames],
            ["ecosysenv", this.EcoSysEnv],
            ["packageflag", this.PackageFlag]
        ]
            .filter(val=>val[1])
            .map(x=>x.join('='))
            .join('&');
    }

    public get searchString(){
        return [
            ["ecoSysProjectId", this.ProjectId],
            ["ecoSysProjectName", this.ProjectName],
            ["ecoSysArea", this.EcoSysArea],
            ["phase", this.Phase],
            ["rule", this.Rule],
            ["header", this.Header],
            ["pathnames", this.PathNames],
            ["packageflag", this.PackageFlag],
            ["ecosysenv", this.EcoSysEnv],
        ]
            .filter(val=>val[1])
            .map(x=>x.join('='))
            .join('&');
    }
}