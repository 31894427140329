import React from 'react';
import {BatchFile} from "../Utilities/Classes/BatchFile";
import {Button, Divider, IconButton, Paper, Popover} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export const MassUpdateFileMenuButton = ({file, setMenuFile, setAnchorEl}: {file: BatchFile, setAnchorEl: (x: any)=>void, setMenuFile: (x: BatchFile)=>void})=>{
    function handleOpen(e: any){
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);
        setMenuFile(file);
    }

    return <>
        <IconButton onClick={handleOpen}>
            <MenuIcon/>
        </IconButton>
    </>
}

export const MassUpdateFileMenu = ({file, anchorEl, handleClose, massUpdate}: {file?: BatchFile, anchorEl?: Element | null, massUpdate: (files: BatchFile[])=>void, handleClose: ()=>void})=>{

    return (
        <>
            <Popover
                onClose={handleClose}
                open={!!file}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                    <Button
                        style={{justifyContent: 'start'}}
                        onClick={()=>{window.open(file?.massUpdateViewerUrl, '_blank'); handleClose()}}
                    >
                        View phase file
                    </Button>
                    <Button
                        style={{justifyContent: 'start'}}
                        onClick={()=>{file?.openInBim360(); handleClose()}}
                    >
                        Open phase file in BUILD
                    </Button>
                    {file?.sourceVersionId ? <>
                        <Divider/>
                        <Button
                            style={{justifyContent: 'start'}}
                            onClick={()=>{window.open(file?.massUpdateViewerSourceUrl, '_blank'); handleClose()}}
                        >
                            View source file
                        </Button>
                        <Button
                            style={{justifyContent: 'start'}}
                            onClick={()=>{file?.openSourceInBim360(); handleClose()}}
                        >
                            Open source file in BUILD
                        </Button>
                        <Divider />
                        {file?.behindSource ? <Button
                            style={{justifyContent: 'start'}}
                            onClick={()=>{
                                massUpdate([file]);
                                handleClose();
                            }}
                        >
                            Update file from Source
                        </Button> : <></>}
                    </> : <></>}
                </Paper>
            </Popover>
        </>
    )
}