export class ViewerTypes {
    public static get forgeOptions() {
        return [
            "3dm",
            "3ds",
            "a",
            "asm",
            "axm",
            "brd",
            "catpart",
            "catproduct",
            "cgr",
            "collaboration",
            "dae",
            "ddx",
            "ddz",
            "dgk",
            "dgn",
            "dlv3",
            "dmt",
            "dwf",
            "dwfx",
            "dwg",
            "dwt",
            "dxf",
            "emodel",
            "exp",
            "f3d",
            "fbrd",
            "fbx",
            "fsch",
            "g",
            "gbxml",
            "glb",
            "gltf",
            "iam",
            "idw",
            "ifc",
            "ifw",
            "ige",
            "iges",
            "igs",
            "ipt",
            "iwm",
            "jt",
            "max",
            "model",
            "mpf",
            "msr",
            "neu",
            "nwc",
            "nwd",
            "obj",
            "osb",
            "par",
            "pdf",
            "pmlprj",
            "pmlprjz",
            "prt",
            "psm",
            "psmodel",
            "rcp",
            "rvt",
            "sab",
            "sat",
            "sch",
            "session",
            "skp",
            "sldasm",
            "sldprt",
            "smb",
            "smt",
            "ste",
            "step",
            "stl",
            "stla",
            "stlb",
            "stp",
            "stpz",
            "vpb",
            "vue",
            "wire",
            "x_b",
            "x_t",
            "xas",
            "xpr",
            "zip",
            "asm\\.\\d+$",
            "neu\\.\\d+$",
            "prt\\.\\d+$"
        ]
    }

    public static get googleOptions() {
        return [
            "doc",
            "docm",
            "docx",
            'pdf',
            "ppt",
            "pptm",
            "pptx",
            "csv",
            "odp",
            "ods",
            "odt",
            "png",
            "rtf",
            "tiff",
            "txt",
            "xml",
            "xls",
            "xlsb",
            "xlsm",
            "xlsx",
            "pdf"
        ]
    }

    public static get microsoftOptions() {
        return [
            "xls",
            "xlsb",
            "xlsm",
            "xlsx",
            "doc",
            "docm",
            "docx",
            'pdf',
        ]
    }

    public static get imgOptions() {
        return [
            "bmp",
            "ico",
            "cur",
            "webp",
            "svg",
            "png",
            "jpg",
            "jpeg",
            "jfif",
            "pjpeg",
            "pjp",
            "gif",
            "avif",
            "apng"
        ]
    }
}