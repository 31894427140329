import {IBimFile, BimFile} from "./BimFile";
import {makeAutoObservable} from "mobx";
import {EcoSysParams} from "./EcoSysParams";

export interface IContentsResponse{
    hubId: string;
    projectId: string;
    folderId: string;
    authorized: boolean;
    isApplicationAdmin: boolean;
    files: IBimFile[];
}

export class Contents{
    public readonly hubId: string;
    public readonly projectId: string;
    public readonly folderId: string;
    public readonly authorized: boolean;
    public readonly isApplicationAdmin: boolean;
    public files: BimFile[];
    public ecoSysParams: EcoSysParams;

    constructor(params:IContentsResponse, ecoSysParams: EcoSysParams) {
        makeAutoObservable(this);

        this.hubId = params.hubId;
        this.projectId = params.projectId;
        this.folderId = params.folderId;
        this.authorized = params.authorized;
        this.isApplicationAdmin = params.isApplicationAdmin;
        this.files = params.files.map(file=>new BimFile(file, this));
        this.ecoSysParams = ecoSysParams;
    }

    public removeFile(file: BimFile){
        this.files = this.files.filter(f=>f !== file);
    }

    public get folderUrl(){
        //Use this for Bim 360:
        // return `https://docs.b360.autodesk.com/projects/${this.projectId}/folders/${this.folderId}/detail`

        //Use this for ACC Build
        return `https://acc.autodesk.com/docs/files/projects/${this.projectId}?folderUrn=${this.folderId}`
    }

}