import React from 'react';
import {BimFile} from "../Utilities/Classes/BimFile";
import {IconRevit} from "./IconRevit";
import {IconDwg} from "./IconDwg";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileWord,
    faFileExcel,
    faFilePdf,
    faFileImage,
    faFilePowerpoint,
    faFileCsv
} from "@fortawesome/free-solid-svg-icons";


export const FileIcon = ({file}: {file: BimFile})=>{
    switch(file.fileType.toLowerCase()){
        case 'empty': return <></>;
        case "rvt": return <IconRevit/>;
        case "dwg": return <IconDwg/>;
        case "csv": return <FontAwesomeIcon icon={faFileCsv}/>;
        case "doc": case "docx": case "docm": return <FontAwesomeIcon icon={faFileWord}/>;
        case "ppt": case "pptx": case "pptm": return <FontAwesomeIcon icon={faFilePowerpoint}/>;
        case "xlsb": case "xls": case "xlsx": case "xlsm": return <FontAwesomeIcon icon={faFileExcel}/>;
        case "jpg": case "png": case "heic": case "jpeg": case "gif": return <FontAwesomeIcon icon={faFileImage}/>;
        case "pdf": return <FontAwesomeIcon icon={faFilePdf}/>;
        default: return <InsertDriveFileOutlinedIcon/>
    }
}