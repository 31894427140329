import React, {useMemo} from 'react';
import {useEcoSysParams} from "../Utilities/Hooks/useEcoSysParams";
import {Button, Divider, IconButton, Paper, Popover} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {ApiCalls} from "../Utilities/ApiCalls";
import {useParams, NavLink} from "react-router-dom";
import {Contents} from "../Utilities/Classes/Contents";
import {useHistory, useLocation} from "react-router";

export const Header = ({children, leftButtons, login, contents}: { children?: any, leftButtons?: any, login?: any, contents?: Contents })=>{

    const ecoSysParams = useEcoSysParams();
    const {itemId} = useParams<{itemId: string}>();
    const history = useHistory();
    const {pathname, search} = useLocation();

    const file = useMemo(()=>{
        return contents?.files.find(x=>x.itemId === itemId);
    }, [contents, itemId])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    function logout(){
        ApiCalls.Logout()
                .then(()=>{
                    window.location.reload();
                })
    }

    function openNewWindow(){
        window.open(window.location.href, "_blank");
    }

    function handleOpenInBim360(e: any){
        e.preventDefault();
        e.stopPropagation();

        if(file){
            window.open( file.fileUrl, '_blank', 'noopener noreferrer')
        }
    }

    const base = pathname.split('/').filter(x=>x).shift();
    console.log("BASE", base)

    function navigateToSettings(){
        history.push(`/${base}/settings${search}`)
    }

    return <header>
        <img alt={"Autodesk Logo"} style={{height: '30px', width: '30px'}} src={'/images/autodesk-logo-blk.svg'}/>
        <NavLink to={`/${base}${search}`}>
            <h1 color={'black'}>{window.decodeURIComponent(ecoSysParams?.ProjectName ?? "")}</h1>
        </NavLink>
        {leftButtons}
        <div style={{flex: 1}}/>
        {children}

        {!login ? <>
            <IconButton onClick={handlePopoverOpen}>
                <MenuIcon/>
            </IconButton>
            <Popover
                    onClose={handlePopoverClose}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
            >
                <Paper style={{padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <Button style={{justifyContent: 'start'}} onClick={()=>openNewWindow()}>Open in New Window</Button>

                    {contents?.authorized && !file ? <NavLink
                            style={{justifyContent: 'start'}}
                            to={{pathname: contents.folderUrl}}
                            target={"_blank"}
                            rel="noopener noreferrer"
                            component={Button}
                    >Open folder in Build</NavLink> : <></>}

                    {file ? <Button style={{justifyContent: 'start'}} onClick={handleOpenInBim360}>Open in Build</Button> : <></>}
                    {file ? (
                                    <NavLink
                                            download={true}
                                            style={{justifyContent: 'start'}}
                                            to={file.downloadUrl}
                                            target={"_blank"}
                                            rel="noopener noreferrer"
                                            component={Button}
                                    >Download File</NavLink>
                            )
                            : <></>}
                    <Divider/>
                    {contents?.isApplicationAdmin && <>
                        <Button style={{justifyContent: 'start'}} onClick={()=>navigateToSettings()}>Settings</Button>
                        <Divider/>
                    </>}
                    <Button style={{justifyContent: 'start'}} variant={'outlined'} onClick={()=>logout()}>Logout of Document Viewer</Button>
                </Paper>
            </Popover>
        </> : <></>}

    </header>
}