import React from 'react'
import {Button, CircularProgress} from "@mui/material";
import {makeStyles} from '@mui/styles'
import {green} from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const ActionButton = ({size, running, color, variant, onClick, text, children, autoFocus}: {autoFocus?: boolean, color?: 'primary', size?: "medium" | "large" | "small" | undefined, variant?: undefined | 'contained' | 'outlined', running: boolean, onClick: (e: any)=>void, text?: string, children?: any})=>{
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button
                size={size}
                disabled={running}
                variant={variant}
                onClick={onClick}
                color={color}
                autoFocus={autoFocus}
            >{text} {children}</Button>
            {running && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}