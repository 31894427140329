import {BatchContents} from "./BatchContents";
import {BimFile, IBimFile} from "./BimFile";
import {EcoSysParams} from "./EcoSysParams";
import dayjs, {Dayjs} from "dayjs";

export interface IBatchBimFile extends IBimFile{
    lastUpdate: string;
    pathNameAncestors: string[];
    attributes: IAttribute[];
    sourceId: string;
    sourceVersionId: string;
    sourceLastModified: string;
    sourceUrl: string;
}

export interface IAttribute {
    name: string;
    value: string;
}

export class Attribute {
    name: string;
    value: string;

    constructor(props: IAttribute) {
        this.name = props.name;
        this.value = props.value;
    }
}

export class BatchFile extends BimFile{
    public batchId?: string;
    public pathNameAncestors: string[];
    public simplifiedPathNameAncestors: string[] = [];
    public attributes: Attribute[];
    public paths: {[key: string]: string};
    public sourceId: string;
    public sourceVersionId: string;
    public sourceLastModified?: Dayjs;
    public sourceUrl?: string;

    constructor(props: IBatchBimFile, contents: BatchContents, folderId?: string) {
        super(props, contents, folderId);
        this.pathNameAncestors = props.pathNameAncestors;
        this.attributes = props.attributes.map(attribute => new Attribute(attribute));
        this.paths = this.pathNameAncestors.reduce((acc: {[key: string]: string}, val, index)=>{
            acc[index.toString()] = val;
            return acc;
        }, {})
        this.sourceId = props.sourceId;
        this.sourceVersionId = props.sourceVersionId;
        this.sourceLastModified = props.sourceLastModified ? dayjs(props.sourceLastModified) : undefined;
        this.sourceUrl = props.sourceUrl;
    }

    public get interface(){
        return {
            name: this.name,
            itemId: this.itemId,
            objectId: this.objectId,
            versionId: this.versionId,
            derivativeId: this.derivativeId,
            fileType: this.fileType,
            hubId: this.hubId,
            projectId: this.projectId,
            folderId: this.folderId,
            version: this.version,
            url: this.url,
            lastUpdate: this.lastUpdate,

            batchId: this.batchId,
            pathNameAncestors: this.pathNameAncestors,
            simplifiedPathNameAncestors: this.simplifiedPathNameAncestors,
            attributes: this.attributes,
            paths: this.paths,
            sourceId: this.sourceId,
            sourceVersionId: this.sourceVersionId,
            sourceLastModified: this.sourceLastModified,
            sourceUrl: this.sourceUrl
        }
    }

    public setBatchId(id: string){
        this.batchId = id;
    }

    public simplifyPathNameAncestors(ecoSysParams: EcoSysParams){
        this.simplifiedPathNameAncestors = this.pathNameAncestors.filter(x=>ecoSysParams.PathNameAncestors.indexOf(x) === -1)
    }

    public get sourceLastUpdateText(){return this.sourceLastModified?.calendar()}

    public get behindSource(){
        if(!this.lastUpdate || !this.sourceLastModified) return undefined;
        return this.lastUpdate?.isBefore(this.sourceLastModified)
    }

    public openSourceInBim360(){
        window.open(this.sourceUrl, '_blank')
    }

    public openInViewer(){
        window.open(`/single/singleFile/:itemId/?`, '_blank')
    }
}