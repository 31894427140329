import React from 'react';
import {Header} from "../Components/Header";
import {Autocomplete, Button, TextField} from "@mui/material";
import {ViewerTypes} from "../Utilities/Classes/ViewerTypes";
import {appState} from "../Utilities/AppState";
import {observer} from "mobx-react";
import {Contents} from "../Utilities/Classes/Contents";

export const SettingsPage = observer(({contents}: {contents?: Contents})=>{

    return (
            <>
                <Header contents={contents}/>

                <main>


                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '1000px', padding: '35px'}}>


                        <TextField
                                label={"Autodesk Account ID (Without 'b.' at the start)"}
                                value={appState.settings.accountId}
                                onChange={(e)=>appState.settings.setAccountId(e.currentTarget.value ?? "")}
                        />

                        <div style={{display: 'flex', gap: '10px'}}>
                            <Autocomplete style={{flex: 1}}
                                    disableCloseOnSelect={true}
                                    value={appState.settings.forgeViewerFormats ?? null}
                                    multiple={true}
                                    renderInput={params=><TextField label={"Forge Viewer"} {...params}/>}
                                    options={ViewerTypes.forgeOptions}
                                    onChange={(e, value)=>appState.settings.setForgeViewerFormats(value ?? [])}
                            />
                            <Button style={{width: '120px'}} onClick={()=>appState.settings.setForgeViewerFormats(ViewerTypes.forgeOptions)}>Select All</Button>
                        </div>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <Autocomplete style={{flex: 1}}
                                    disableCloseOnSelect={true}
                                    value={appState.settings.microsoftViewerFormats ?? null}
                                    multiple={true}
                                    renderInput={params=><TextField label={"Microsoft Viewer (Sends files to a Microsoft server for processing. Cannot be used inside a firewall.)"} {...params}/>}
                                    options={ViewerTypes.microsoftOptions}
                                    onChange={(e, value)=>appState.settings.setMicrosoftViewerFormats(value ?? [])}
                            />
                            <Button style={{width: '120px'}} onClick={()=>appState.settings.setMicrosoftViewerFormats(ViewerTypes.microsoftOptions)}>Select All</Button>
                        </div>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <Autocomplete style={{flex: 1}}
                                    disableCloseOnSelect={true}
                                    value={appState.settings.googleViewerFormats ?? null}
                                    multiple={true}
                                    renderInput={params=><TextField label={"Google Viewer (Sends files to a Google server for processing)"} {...params}/>}
                                    options={ViewerTypes.googleOptions}
                                    onChange={(e, value)=>appState.settings.setGoogleViewerFormats(value ?? [])}
                            />
                            <Button style={{width: '120px'}} onClick={()=>appState.settings.setGoogleViewerFormats(ViewerTypes.googleOptions)}>Select All</Button>
                        </div>

                        <Button variant={'contained'} onClick={()=>appState.settings.save()}>Save Changes</Button>
                    </div>
                </main>
            </>
    )
});