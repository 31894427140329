import {useEffect, useState} from "react";
import {ApiCalls} from "../ApiCalls";
import {BimFile} from "../Classes/BimFile";

export function useViewer(file: BimFile, containerId: string){
    const [viewer, setViewer] = useState<any>();

    useEffect(()=>{
        //Clear out the viewer on file change
        viewer?.finish();
        setViewer(undefined);

        if(file) {
            const options = {
                env: 'AutodeskProduction',
                getAccessToken: (onTokenReady: Function) => {
                    ApiCalls.GetToken()
                        .then(token => {
                            //@ts-ignore
                            onTokenReady(token.accessToken, 3500)
                        })
                        .catch(err=>{
                            window.location.reload();
                        })
                }
            }

            //@ts-ignore
            Autodesk.Viewing.Initializer(options, function () {
                const htmlDiv = document.getElementById(containerId);

                //@ts-ignore
                const guiViewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, {});

                const startedCode = guiViewer.start();

                if (startedCode > 0) {
                    console.error("Couldn't Create Viewer")
                }
                //@ts-ignore
                Autodesk.Viewing.Document.load(file.derivativeId, (viewerDocument) => {
                    const selectedViewable = viewerDocument
                        .getRoot()
                        .search({'type': 'geometry'})[0]

                    const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
                    guiViewer.loadDocumentNode(viewerDocument, selectedViewable ?? defaultModel);
                    guiViewer.loadExtension('Autodesk.DocumentBrowser');
                }, (e: any) => {
                    setViewer(undefined);
                    if (htmlDiv) {
                        htmlDiv.innerHTML = `
                            <div style='text-align: center'>
                            <h1>File Type cannot be displayed in Viewer</h1>
                            <p>
                            <a href="${file.fileUrl}" target='_blank' noopener noreferrer>
                             Open in Build
                            </a>
                            </p>
                            <p>
                            <a download href="${file.downloadUrl}">
                             Download File
                            </a>
                            </p>
                            </div>`
                    }
                });
                setViewer(guiViewer)
            })
        }
    }, [file, containerId])
}