import React from 'react';
import {Header} from "../Components/Header";

export const EcoSysCredentialsProblemPage = ()=>{

    return (
        <>
            <Header/>
            <div style={{display: 'flex', flexDirection: 'column', padding: '15px', alignItems: "center", height: '100%', justifyContent: 'center'}}>
                <h1>Could not validate project access with EcoSys </h1>
                <div>Please ask your EcoSys Admin to update the Autodesk Build integration service account</div>
            </div>
        </>
    )
}