import React, {useEffect, useState} from 'react';
import {ApiCalls} from "./Utilities/ApiCalls";
import {LoginPage} from "./Pages/LoginPage";
import {LoadingPage} from "./Pages/LoadingPage";
import {Route, Switch} from 'react-router-dom';
import {useEcoSysParams} from "./Utilities/Hooks/useEcoSysParams";
import {Contents} from "./Utilities/Classes/Contents";
import {MissingParametersPage} from "./Pages/MissingParametersPage";
import {FramePage} from "./Pages/FramePage";
import {AddFilePage} from "./Pages/AddFilePage";
import {NotAuthorizedPage} from "./Pages/NotAuthorizedPage";
import {appState} from "./Utilities/AppState";
import {SingleFilePage} from "./Pages/SingleFilePage";
import {NotCreated} from "./Pages/NotCreatedPage";
import {NotSetup} from "./Pages/NotSetupPage";
import {ErrorPage} from "./Pages/ErrorPage";
import {NotInEcoSys} from "./Pages/NotInEcoSysPage";
import {EcoSysCredentialsProblemPage} from "./Pages/EcoSysCredentialsProblemPage";
import {SettingsPage} from "./Pages/SettingsPage";

function SingleApp() {
    const [contents, setContents] = useState<Contents>();
    const [loginUrl, setLoginUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [hubName, setHubName] = useState<string>("Hub Not Found");
    const [projectDoesNotExist, setProjectDoesNotExist] = useState<boolean>(false);
    const [projectNotSetup, setProjectNotSetup] = useState<boolean>(false);
    const [forbidden, setForbidden] = useState<any | boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const ecoSysParams = useEcoSysParams();

    useEffect(()=>{
        if(ecoSysParams?.isValid){
            setLoading(true);
            appState.setLoading(true)
            ApiCalls.GetFrame({ecoSysParams: ecoSysParams})
                    .then((response)=>{
                        const contents = new Contents(response, ecoSysParams);
                        setContents(contents);
                        appState.setContents(contents);
                        appState.setEcoSysParams(ecoSysParams);
                    })
                    .catch((err)=>{
                        switch (err.response?.status){
                            case 401:
                                //If unauthorized, user must log in
                                return ApiCalls.GetLoginUrl()
                                        .then(result=>{
                                            setLoginUrl(result);
                                        })
                                        .catch(()=>{
                                            setError(true);
                                        })
                            case 404:
                                //If the project doesn't exist, then notify the user
                                if(err.response.data?.errorCode === "NoProject"){
                                    setProjectDoesNotExist(true);
                                } else if (err.response.data?.errorCode === "NoEcoSysFolder"){
                                    setProjectNotSetup(true);
                                } else {
                                    setProjectDoesNotExist(true);
                                }
                                setHubName(err.response.data?.accountName);
                                break;
                            case 403:
                                //If the project exists, but the user isn't allowed to see it
                                setForbidden(err.response.data || true);
                                break;
                            case 400:
                                setError(true);
                                setErrorMsg(err.response.data);
                        }
                    })
                    .finally(()=>{
                        setLoading(false);
                        appState.setLoading(false)
                    })
        } else {
            setLoading(false);
            appState.setLoading(false)
        }
    }, [ecoSysParams])

    useEffect(()=>{
        window.onfocus =()=>{
            if(!appState.contents && !appState.loading){
                window.location.reload();
            }
        }
    }, [])

    if(loading){
        return <LoadingPage/>
    } else if(!ecoSysParams?.isValid){
        return <MissingParametersPage/>
    } else if(loginUrl) {
        return <LoginPage loginUrl={loginUrl}/>
    } else if(forbidden.result === 'notallowed'){
        return <NotAuthorizedPage/>
    } else if(forbidden.result === 'dne'){
        return <NotInEcoSys />
    } else if(forbidden.result === 'ecosysauthfailed'){
        return <EcoSysCredentialsProblemPage />
    } else if(projectDoesNotExist){
        return <NotCreated hubName={hubName}/>
    } else if(projectNotSetup){
        return <NotSetup hubName={hubName}/>
    }  else if(error){
        return <ErrorPage errorMessage={errorMsg}/>
    } else {
        if(contents){
            return (
                    <Switch>
                        <Route path={"/single/settings"} render={()=><SettingsPage contents={contents}/>}/>
                        <Route exact path={'/single'} render={()=><FramePage ecosysParams={ecoSysParams} contents={contents!}/>}/>

                        <Route exact path={'/single/singleFile/:itemId'} render={()=><SingleFilePage ecosysParams={ecoSysParams} contents={contents!}/>}/>


                        {ecoSysParams.isEditable ?
                                <Route exact path={'/single/copy'} render={()=><AddFilePage ecoSysParams={ecoSysParams} contents={contents!}/>}/>
                                : <></>}
                    </Switch>
            )
        } else {
            return <NotAuthorizedPage/>
        }
    }
}

export default SingleApp;
