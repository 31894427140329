import React from 'react';
import {observer} from "mobx-react";
import {Button, Typography} from "@mui/material";
import {BimFile} from "../Utilities/Classes/BimFile";
import {Contents} from "../Utilities/Classes/Contents";

export const NonViewable = observer(({contents, file}: {contents: Contents, file: BimFile})=>{

    //TODO: Add Delete Button
    //TODO: Add Back Button if multiple files


    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20%'}}>
        <Typography>This file cannot be displayed inside EcoSys. Please click below to open the file</Typography>

        {contents.authorized ?
            <>
                <a href={file.fileUrl} target={"_blank"} rel="noopener noreferrer">
                    <Button variant={'outlined'}>Open file in Build</Button>
                </a>
                <a href={file.downloadUrl} download={true}>
                    <Button>Download File</Button>
                </a>
            </>
            :
            <a href={file.downloadUrl} download={true}>
            <Button variant={'outlined'}>Download File</Button>
            </a>
        }
    </div>
})