import React from 'react';
import {Button} from "@mui/material";
import {Header} from "../Components/Header";

export const LoginPage = ({loginUrl}: {loginUrl: string})=>{

    function loginToBim360(e: any){
        e.preventDefault();
        window.open(loginUrl, '_blank', `menubar=false,height=800,width=600`)
    }

    return (
        <>
            <Header login={true}/>

            <div style={{display: 'flex', flexDirection: 'column', padding: '15px', alignItems: "center", height: '100%', justifyContent: 'center'}}>
                <h2>Sign into Document Viewer</h2>

                <a href={loginUrl} target={"_blank"} rel={"noopener noreferrer"} onClick={loginToBim360} style={{marginBottom: '15vh'}}>
                    <Button size={'large'} disabled={!loginUrl} color={'primary'} variant={"outlined"}>
                        Login to Autodesk Build using Koch ID
                    </Button>
                </a>
            </div>
        </>
    )
}