import React from 'react';
import {Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@mui/material";

export const LoadingDialog = ({count}: {count: number})=>{

    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title">Loading File...</DialogTitle>
            <DialogContent style={{width: '300px'}}>
                <DialogContentText id="alert-dialog-description">

                    <div style={{marginBottom: '2em'}}>
                        <small>
                            {count > 1 && <div>Attempt {count}</div>}
                        </small>
                    </div>

                    <LinearProgress/>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}