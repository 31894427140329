import React from 'react';
import {useEcoSysParams} from "../Utilities/Hooks/useEcoSysParams";
import {Header} from "../Components/Header";

export const NotInEcoSys = ()=>{
    const ecoSysParams = useEcoSysParams();

    return (
        <>
            <Header/>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', padding: '15px', alignItems: "center", height: '100%', justifyContent: 'center'}}>
                <h1>Project <u>{ecoSysParams?.ProjectId}</u> was not found in EcoSys</h1>
                <div>Please contact your EcoSys Admin</div>
            </div>
        </>
    )
}