import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {appState} from "../Utilities/AppState";

export const ErrorAlert = ()=>{

    const errors = appState.errors;
    function handleClose(){
        appState.clearErrors();
    }

    return (
            <Dialog
                open={!!errors.length}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errors.map((message, i)=>(<div key={i}>{message}</div>))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button>Close</Button>
                </DialogActions>
            </Dialog>
    );
}