import {useEffect, useState} from "react";
import {EcoSysParams, IEcoSysParams} from "../Classes/EcoSysParams";
import {useLocation} from "react-router";

export function useEcoSysParams(){
    const [params, setParams] = useState<EcoSysParams>();
    const {search} = useLocation();

    useEffect(()=>{
        const ecosysParams = search
            .slice(1)
            .split('&')
            .map(val=>val.split('='))
            .reduce((acc: IEcoSysParams, val: string[])=>{
                //@ts-ignore
                acc[val[0].toLowerCase()] = val[1];
                return acc;
            }, {} as IEcoSysParams);
        try{
            const newParams = new EcoSysParams(ecosysParams)
            setParams(newParams);
        } catch (e){
            console.error("Params were invalid");
        }
    }, [search])

    return params;
}

