import React from 'react';
import {useEcoSysParams} from "../Utilities/Hooks/useEcoSysParams";

export const NotSetup = ({hubName}: { hubName: string })=>{
    const ecoSysParams = useEcoSysParams();
    const name = [ecoSysParams?.ProjectId, ecoSysParams?.ProjectName]
        .filter(x=>x)
        .map((x)=>decodeURIComponent(x!))
        .join(" - ")

    return (
        <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', padding: '15px', alignItems: "center", height: '100%', justifyContent: 'center'}}>
            <h1>Project <u>{name}</u> has not been set up</h1>
            <div>Please ask your Autodesk Build Project Admin to create a folder named</div>
                <div style={{fontWeight: 'bold'}}>'EcoSys Integration'</div>
            <div> in the 'Project Files' folder.</div>
        </div>
    )
}