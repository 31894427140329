import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {BimFile} from "../Utilities/Classes/BimFile";
import {LoadingDialog} from "./LoadingDialog";

let loaded = false;

export const GoogleViewable = observer(({file}: {file: BimFile})=>{
    const [loadAttempt, setLoadAttempt] = useState(1);
    //The viewer does not always successfully load, leaving an empty page with no content for an extended period of time.
    //To resolve this, we track whether or not the 'onLoad' event has been called, and reload the iframe if it has not.
    //Using useState caused issues, which is why 'loaded' is set outside of the component.
    useEffect(()=>{
        loaded = false;
        reload();
    }, [file])

    function reload(){
        setTimeout(()=>{
            if(!loaded){
                const iframe = window.document.getElementById('googleIframe') as HTMLIFrameElement;
                iframe.src = iframe.src + "&timestamp=1"
                setLoadAttempt(loadAttempt + 1);
                reload();
            }
        }, 5000)
    }

    function frameload(event: any){
        loaded = true;
        setLoadAttempt(0);
    }

    return <div className={'viewerContainer'} style={{position: "relative", display: 'flex', height: '100%', flexDirection: 'column', flex: 1}}>
        <iframe
            onLoad={frameload}
            // src={`https://docs.google.com/viewer?url=${file.googleDownloadUrl}&embedded=true`}
            // src={`https://docs.google.com/viewer?url=${file.s3Url}&embedded=true`}
            src={file.embedViewerUrl({type: 'google'})}
            id={"googleIframe"}
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'}}
            frameBorder='0'
            title={"Google File Viewer"}
        >
            This is an embedded document, powered by <a target='_blank' href='http://docs.google.com' rel="noopener noreferrer">Google Docs Online</a>
        </iframe>
        {!!loadAttempt && <LoadingDialog count={loadAttempt}/>}
    </div>
})