import React from 'react';
import {CircularProgress} from "@mui/material";

export const LoadingPage = ()=>{
    return (
        <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress />
                <h1>Loading...</h1>
            </div>
        </div>
        )
}